import { toast } from 'components/Toast/Toast';
import { EntityStatusEnum } from 'types/data-management';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Resource } from 'types/entities/resources';
import { BaseAPIResponse } from 'types/utilities';
import { http } from 'utilities/http/http';

export async function fetchStagedResources(
  pageIndex: number,
  pageSize: number,
): Promise<Resource[]> {
  try {
    const response = await http.get<BaseAPIResponse<Resource[]>>(
      `/staged-resource`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Resources');
    }
  } catch (err: unknown) {
    toast.error('Error List Resources', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Resources: ${err}`);
  }
}

export async function getResourceById(
  resourceId: string,
  token: string,
  isRefresh: boolean,
): Promise<Resource> {
  try {
    const response = await http.get<BaseAPIResponse<Resource>>(
      `/staged-resource/${resourceId}/${token}/${isRefresh}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Get Resource');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error Get Resources: ${err}`);
  }
}

export async function getOriginalResourceById(
  resourceId: string,
  token: string,
): Promise<Resource> {
  try {
    const response = await http.get<BaseAPIResponse<Resource>>(
      `/staged-resource/${resourceId}/${token}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Get Resource');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error Get Resources: ${err}`);
  }
}

export async function createResource(
  name: string,
  email: string,
  objectId: string,
  language: string,
): Promise<Resource> {
  try {
    const response = await http.post<BaseAPIResponse<Resource>>(
      `/staged-resource`,
      {
        name: name,
        email: email,
        type: 'resource',
        objectId: objectId,
        language,
      },
    );

    if (response.success) {
      toast.success('Record verification has been requested', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error: Resource Verification');
    }
  } catch (err: unknown) {
    toast.error('Error: Resource Verification. Please, try again.', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error: Resource Verification: ${err}`);
  }
}

export async function updateResource(
  resourceId: string,
  resource: Resource,
): Promise<Resource> {
  try {
    const response = await http.patch<BaseAPIResponse<Resource>>(
      `/staged-resource/${resourceId}`,
      resource,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error updating the resources');
    }
  } catch (err: unknown) {
    toast.error('Error updating the resources. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error updating the resources: ${err}`);
  }
}

export async function updateStatusResourceToPendingApproval(
  resourceId: string,
  token: string,
): Promise<Resource> {
  try {
    const response = await http.patch<BaseAPIResponse<Resource>>(
      `/staged-resource/status/${resourceId}/${token}`,
      {
        status: EntityStatusEnum.PendingApproval,
      },
    );

    if (response.success) {
      toast.success('The record has been submitted for final approval.', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error updating the resources');
    }
  } catch (err: unknown) {
    toast.error('Error updating the resources. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error updating the resources: ${err}`);
  }
}

export async function approveResourceByVerifier(
  resourceId: string,
  token: string,
): Promise<Resource> {
  try {
    const response = await http.patch<BaseAPIResponse<Resource>>(
      `/staged-resource/status/${resourceId}/${token}/verify`,
    );

    if (response.success) {
      toast.success('The Resource was approved successfully', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error approving the resource');
    }
  } catch (err: unknown) {
    toast.error('Error approving the resource. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error approving the resource: ${err}`);
  }
}

export async function approveResource(resourceId: string): Promise<Resource> {
  try {
    const response = await http.patch<BaseAPIResponse<Resource>>(
      `/staged-resource/status/${resourceId}/approve`,
    );

    if (response.success) {
      toast.success('The record was approved successfully.', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error approving the resource');
    }
  } catch (err: unknown) {
    toast.error('Error approving the resource. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error approving the resource: ${err}`);
  }
}

export async function rejectResource(resourceId: string): Promise<Resource> {
  try {
    const response = await http.patch<BaseAPIResponse<Resource>>(
      `/staged-resource/status/${resourceId}/reject`,
    );

    if (response.success) {
      toast.success('The Resource was rejected successfully', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error rejecting the resource');
    }
  } catch (err: unknown) {
    toast.error('Error rejecting the resource. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error rejecting the resource: ${err}`);
  }
}

export async function deleteResource(resourceId: string): Promise<Resource> {
  try {
    const response = await http.delete<BaseAPIResponse<Resource>>(
      `/staged-resource/${resourceId}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error delete Resources');
    }
  } catch (err: unknown) {
    toast.error('Error deleting resource. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error delete Resources: ${err}`);
  }
}
