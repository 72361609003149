/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Add, Remove } from '@mui/icons-material';
import Popper, { PopperProps } from '@mui/material/Popper';
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  fetchTaxonomyById,
  searchTaxonomies,
} from 'api/taxonomies/taxonomies.api';
import { FC, useEffect, useState } from 'react';
import { intl } from 'utilities/i18n/intl.utility';
import { ModalParagraph } from '../ModalTitles/ModalParagraph';
import { toast } from 'react-toastify';
import { Taxonomy } from 'types/entities/taxonomies';

type TaxonomiesSelectProps = {
  selectedTaxonomies: string[][];
  setSelectedTaxonomies: (values: string[][]) => void;
};
type TaxonomyOption = {
  label: string;
  value: string;
};

export const TaxonomiesSelect: FC<TaxonomiesSelectProps> = ({
  selectedTaxonomies,
  setSelectedTaxonomies,
}) => {
  // const [loading, setLoading] = useState(false);
  // const [taxonomies, setTaxonomies] = useState<
  //   { label: string; value: string }[]
  // >([]);
  const [filteredTaxonomies, setFilteredTaxonomies] = useState<
    TaxonomyOption[][]
  >([]);
  const [inputValue, setInputValue] = useState('');

  const handleFilterChange = async (searchText: string, index: number) => {
    let searchedTaxonomies: any[] = [];

    if (searchText.length < 3) return;

    try {
      if (searchText.includes('En') && searchText.includes('Fr')) {
        const search = searchText.split('/')[0].split(': ')[1].trimEnd();
        if (search) {
          const { taxonomies } = await searchTaxonomies('nameEN', search);
          searchedTaxonomies = taxonomies;
        }
      } else {
        if (searchText) {
          const { taxonomies } = await searchTaxonomies('nameEN', searchText);
          searchedTaxonomies = taxonomies;
        }
      }

      const filteredTaxonomies =
        searchedTaxonomies.length > 0
          ? searchedTaxonomies.map((tax) => {
              return {
                label: `En: ${tax.nameEN} / Fr: ${tax.nameFR} - (${
                  tax.objectId || tax._id
                })`,
                value: tax.objectId || tax._id,
              };
            })
          : [];

      if (filteredTaxonomies.length > 0) {
        setFilteredTaxonomies((prev) =>
          prev.map((arr, idx) => (idx === index ? filteredTaxonomies : arr)),
        );
      }
    } catch (error) {
      toast.error('Failed to search', { position: 'top-center' });
    }
  };

  const handleSelectTaxonomies = (selectedOptions: any, index: number) => {
    const newGroupOfTaxonomies = selectedOptions
      ? selectedOptions?.map((option: any) => option.value)
      : [];

    const allSelectedTaxonomies = [...selectedTaxonomies];
    allSelectedTaxonomies[index] = newGroupOfTaxonomies;
    setSelectedTaxonomies(allSelectedTaxonomies);
  };

  const handleAddTaxonomyGroup = () => {
    const newData = [...selectedTaxonomies, []];
    setFilteredTaxonomies((prev) => [...prev, []]);
    setSelectedTaxonomies(newData);
  };

  const handleRemoveTaxonomyGroup = (index: number) => {
    const arrayRemovedIndex = [...selectedTaxonomies];
    setFilteredTaxonomies((prev) =>
      prev.map((arr, idx) => (idx === index ? [] : arr)),
    );
    arrayRemovedIndex.splice(index, 1);
    setSelectedTaxonomies(arrayRemovedIndex);
  };

  useEffect(() => {
    const getTaxonomies = async () => {
      // setLoading(true);
      const ts = [];
      for (const tax of selectedTaxonomies) {
        const taxes = [];
        if (Array.isArray(tax)) {
          for (const t of tax) {
            try {
              const taxonomy = await fetchTaxonomyById(t);
              if (taxonomy) {
                taxes.push(taxonomy as unknown as Taxonomy);
              }
            } catch (_error) {
              /* empty */
            }
          }
        }

        const mappedTaxes = taxes.map((taxonomy) => ({
          label: `[Code: ${taxonomy.code} / EN: ${taxonomy.nameEN} / FR: ${taxonomy.nameFR}]`,
          value: taxonomy?._id,
        }));
        ts.push(mappedTaxes);
      }

      setFilteredTaxonomies(ts);
      // setLoading(false);
    };

    getTaxonomies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <>
        <ModalParagraph>
          {intl.translate({
            id: 'Select existing Taxonomy(s)',
          })}
          <IconButton
            color="primary"
            aria-label="Add item"
            component="label"
            onClick={handleAddTaxonomyGroup}
          >
            <Tooltip
              arrow
              title={intl.translate({
                id: 'Add group',
              })}
            >
              <Add />
            </Tooltip>
          </IconButton>
        </ModalParagraph>

        {selectedTaxonomies?.length &&
          selectedTaxonomies?.map((element, index) => (
            <>
              <Grid item xs={1} key={`grid-${index}`}>
                <IconButton
                  key={`icon-${index}`}
                  size="small"
                  color="error"
                  aria-label="Remove Item"
                  component="label"
                  onClick={() => handleRemoveTaxonomyGroup(index)}
                >
                  <Tooltip
                    key={`tooltip-${index}`}
                    arrow
                    title={intl.translate({
                      id: 'Delete group',
                    })}
                  >
                    <Remove />
                  </Tooltip>
                </IconButton>

                {/* <Select
                key={`select-${index}`}
                isLoading={loading}
                isMulti
                options={filteredTaxonomies}
                value={taxonomies.filter((taxonomy) =>
                  selectedTaxonomies[index].includes(taxonomy.value),
                )}
                onChange={(selectedOptions) =>
                  handleSelectTaxonomies(selectedOptions, index)
                }
                onInputChange={handleFilterChange}
                filterOption={() => true}
                placeholder={intl.translate({
                  id: 'Select an option',
                })}
              /> */}
                {/* <div style={{fontFamily: "inherit"}}>
                <p style={{fontWeight: "bold"}}>Current selections</p>
                <br />
                {
                  taxonomies.filter((taxonomy) =>
                  selectedTaxonomies[index].includes(taxonomy.value),
                ).map(t => <>
                  <p style={{fontWeight: 400, fontFamily: "inherit"}}>{t.label}</p>
                  <br />
                </>)
                }
              </div> */}

                <Autocomplete
                  options={filteredTaxonomies[index] || []}
                  multiple
                  sx={{ width: '100%' }}
                  noOptionsText="Enter a taxonomy name to search"
                  getOptionLabel={(option) => option.label}
                  onChange={(e, newValue) => {
                    handleSelectTaxonomies(newValue, index);
                  }}
                  onInputChange={(e, newValue) => {
                    setInputValue(newValue);
                    handleFilterChange(newValue, index);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={
                          filteredTaxonomies[index]
                            ?.map((st) => st.label)
                            ?.toString() || 'Select'
                        }
                        variant="outlined"
                        onKeyDown={(e) => {
                          if (
                            e.key === 'Enter' &&
                            filteredTaxonomies[index]?.findIndex(
                              (o) => o.label === inputValue,
                            ) === -1
                          ) {
                            setFilteredTaxonomies((prev) =>
                              prev.map((arr, idx) =>
                                idx === index
                                  ? arr.concat([
                                      {
                                        label: inputValue,
                                        value:
                                          filteredTaxonomies[index].find(
                                            (value) =>
                                              value.label === inputValue,
                                          )?.value || '',
                                      },
                                    ])
                                  : arr,
                              ),
                            );
                          }
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </>
          ))}
      </>
    </>
  );
};
