import { STATUS_ACTIVE, STATUS_DEACTIVATED } from 'global-constants/agencies';
import { MRT_Cell, MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Agency } from 'types/entities/agencies';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

export const useAgencies = () => {
  const getCommonDateCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    const date = cell.getValue<Date>();

    return date ? new Date(date).toLocaleDateString() : '';
  };

  const getStatusCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    const status = cell.getValue<boolean>() || false;

    return status ? STATUS_ACTIVE : STATUS_DEACTIVATED;
  };

  const getBooleanCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    const value = cell.getValue<boolean>() || false;
    return value ? 'Yes' : 'No';
  };

  const getJsonCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    try {
      const jsonAsString = JSON.stringify(cell.getValue<string>(), null, 4);
      return (
        <textarea
          disabled
          style={{
            background: 'inherit',
            height: '100px',
            border: 'none',
          }}
          value={jsonAsString || ''}
        ></textarea>
      );
    } catch (e: unknown) {
      if (e instanceof Error) {
        logger.error(e);
      }
      return null;
    }
  };

  const columns = useMemo<MRT_ColumnDef<Agency>[]>(
    () => [
      {
        accessorFn: (row) => row._id || row.id,
        accessorKey: '_id',
        header: intl.translate({
          id: 'Object ID',
        }),
        enableEditing: false,
        enableHiding: false,
      },
      {
        accessorKey: 'khpReferenceNumber',
        header: intl.translate({
          id: 'Khp Reference Number',
        }),
      },
      {
        accessorKey: 'agencyReferenceNumber',
        header: intl.translate({
          id: 'Agency Reference Number',
        }),
      },
      {
        accessorKey: 'lastVerifiedOn',
        header: intl.translate({
          id: 'Last Verified On',
        }),
      },
      {
        accessorKey: 'lastUpdatedBy',
        header: intl.translate({
          id: 'Last Updated By',
        }),
      },
      {
        accessorKey: 'nameEN',
        header: intl.translate({
          id: 'Agency Public Name En',
        }),
      },
      {
        accessorKey: 'nameFR',
        header: intl.translate({
          id: 'Agency Public Name Fr',
        }),
      },
      {
        accessorKey: 'nameDetailsEnOfficial',
        header: intl.translate({
          id: 'Agency Official Name En',
        }),
      },
      {
        accessorKey: 'nameDetailsEnAlternate',
        header: intl.translate({
          id: 'Agency Alternative Names En',
        }),
      },
      {
        accessorKey: 'nameDetailsFrOfficial',
        header: intl.translate({
          id: 'Agency Alternative Names Fr',
        }),
      },
      {
        accessorKey: 'nameDetailsFrAlternate',
        header: intl.translate({
          id: 'Name Details Fr Alternate',
        }),
      },
      {
        accessorKey: 'socialFacebook',
        header: intl.translate({
          id: 'Facebook',
        }),
      },
      {
        accessorKey: 'socialInstagram',
        header: intl.translate({
          id: 'Instagram',
        }),
      },
      {
        accessorKey: 'socialTwitter',
        header: intl.translate({
          id: 'Twitter',
        }),
      },
      {
        accessorKey: 'isActive',
        header: intl.translate({
          id: 'Status',
        }),
        Cell: ({ cell }) => {
          return getStatusCellProps(cell);
        },
      },

      {
        accessorKey: 'locationAddress1',
        header: intl.translate({
          id: 'Agency Primary Location Address 1',
        }),
      },
      {
        accessorKey: 'locationAddress2',
        header: intl.translate({
          id: 'Agency Primary Location Address 2',
        }),
      },
      {
        accessorKey: 'locationCity',
        header: intl.translate({
          id: 'Agency Primary Location City',
        }),
      },
      {
        accessorKey: 'locationCounty',
        header: intl.translate({
          id: 'Agency Primary Location County',
        }),
      },
      {
        accessorKey: 'locationProvince',
        header: intl.translate({
          id: 'Agency Primary Location Province',
        }),
      },
      {
        accessorKey: 'locationCountry',
        header: intl.translate({
          id: 'Agency Location Country',
        }),
      },
      {
        accessorKey: 'locationPostalCode',
        header: intl.translate({
          id: 'Agency Primary Location Postal Code',
        }),
      },
      {
        accessorKey: 'locationDescription',
        header: intl.translate({
          id: 'Agency Location Description',
        }),
      },
      {
        accessorKey: 'locationLongitude',
        header: intl.translate({
          id: 'Agency Location Longitude',
        }),
      },
      {
        accessorKey: 'locationLatitude',
        header: intl.translate({
          id: 'Agency Location Latitude',
        }),
      },
      {
        accessorKey: 'isLocationPrivate',
        header: intl.translate({
          id: 'Primary Location Is Private',
        }),
        Cell: ({ cell }) => {
          return getBooleanCellProps(cell);
        },
      },
      {
        accessorKey: 'email',
        header: intl.translate({
          id: 'Agency Email',
        }),
      },

      {
        accessorKey: 'mailingAddressAddress1',
        header: intl.translate({
          id: 'Mailing Address Line 1',
        }),
      },
      {
        accessorKey: 'mailingAddressAddress2',
        header: intl.translate({
          id: 'Mailing Address Line 2',
        }),
      },
      {
        accessorKey: 'mailingAddressCity',
        header: intl.translate({
          id: 'Mailing Address City',
        }),
      },
      {
        accessorKey: 'mailingAddressCounty',
        header: intl.translate({
          id: 'Mailing Address County',
        }),
      },
      {
        accessorKey: 'mailingAddressProvince',
        header: intl.translate({
          id: 'Mailing Address Province',
        }),
      },
      {
        accessorKey: 'mailingAddressCountry',
        header: intl.translate({
          id: 'Mailing Address Country',
        }),
      },
      {
        accessorKey: 'mailingAddressPostalCode',
        header: intl.translate({
          id: 'Mailing Address Postal Code',
        }),
      },
      {
        accessorKey: 'phoneNumbersString',
        header: intl.translate({
          id: 'Phone Numbers',
        }),
      },
      {
        accessorKey: 'phoneNumbers',
        header: intl.translate({
          id: 'Phone Numbers',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'contactsString',
        header: intl.translate({
          id: 'Contacts',
        }),
      },
      {
        accessorKey: 'contacts',
        header: intl.translate({
          id: 'Contacts',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'websiteEn',
        header: intl.translate({
          id: 'WebsiteEn',
        }),
      },
      {
        accessorKey: 'websiteFr',
        header: intl.translate({
          id: 'WebsiteFr',
        }),
      },
      {
        accessorKey: 'mainContactName',
        header: intl.translate({
          id: 'Main Contact Name',
        }),
      },
      {
        accessorKey: 'mainContactTitleEn',
        header: intl.translate({
          id: 'Main Contact Title en',
        }),
      },
      {
        accessorKey: 'mainContactTitleFr',
        header: intl.translate({
          id: 'Main Contact Title fr',
        }),
      },
      {
        accessorKey: 'mainContactPhone',
        header: intl.translate({
          id: 'Main Contact Phone',
        }),
      },
      {
        accessorKey: 'mainContactEmail',
        header: intl.translate({
          id: 'Main Contact Email',
        }),
      },
      {
        accessorKey: 'legalStatus',
        header: intl.translate({
          id: 'Legal Status',
        }),
      },
      {
        accessorKey: 'updatedAt',
        header: intl.translate({
          id: 'Updated At',
        }),
        Cell: ({ cell }) => {
          return getCommonDateCellProps(cell);
        },
      },
      {
        accessorKey: 'createdAt',
        header: intl.translate({
          id: 'Created At',
        }),
        Cell: ({ cell }) => {
          return getCommonDateCellProps(cell);
        },
      },
      {
        accessorKey: 'objectId',
        header: intl.translate({
          id: 'Object ID',
        }),
      },
    ],
    [],
  );

  return {
    columns,
  };
};
