/* eslint-disable @typescript-eslint/no-unused-vars */
import UpdateIcon from '@mui/icons-material/Update';
import { Search } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import citiesByProvince from 'assets/constants/cities-by-province';
import provinces, {
  AbbreviationsToProvinceMap,
} from 'assets/constants/provinces';
import AccessibilityForm from 'components/Form/References/AccessibilityForm';
import DocumentsRequiredForm from 'components/Form/References/DocumentsRequiredForm';
import FeeStructureSourceForm from 'components/Form/References/FeeStructureSourceForm';
import HowIsServiceOfferedForm from 'components/Form/References/HowIsServiceOfferedForm';
import HowToAccessSupportForm from 'components/Form/References/HowToAccessSupportForm';
import LanguagesForm from 'components/Form/References/LanguagesForm';
import TargetPopulationForm from 'components/Form/References/TargetPopulationForm';
import ApplicationProcessForm from 'components/Form/Sections/ApplicationProcessForm';
import CoverageForm from 'components/Form/Sections/CoverageForm';
import KeywordsForm from 'components/Form/Sections/KeywordsForm';
import OperationsForm from 'components/Form/Sections/OperationsForm';
import PhoneNumbersForm from 'components/Form/Sections/PhoneNumbersForm';
import {
  isValidEnFr,
  newCoverage,
  newLanguage,
  newMailingAddress,
  newOperations,
  newPhoneNumbers,
  newPhysicalAddress,
} from 'helpers/resources.helpers';
import { MRT_ColumnDef } from 'material-react-table';
import { FC, useCallback, useEffect, useState } from 'react';
import { Operations, PhoneNumber } from 'types/data-management';
import {
  Coverage,
  Language,
  MailingAddress,
  PhysicalAddress,
  Resource,
} from 'types/entities/resources';
import { YesNoEnum } from 'types/utilities';
import { intl } from 'utilities/i18n/intl.utility';
import { BoxContent } from '../ModalContent/BoxContent';
import { getGeocodingData } from 'api/geocoding/geocoding.api';
import { toast } from 'react-toastify';

type ResourceFormProps = {
  columns: MRT_ColumnDef<Resource>[];
  values: Resource;
  setValues: (values: Resource) => void;
  validationErrors: { [cellId: string]: string };
  changedProperties?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedSiteInfo?: any[];
};

const renderDisabledTextField = (
  column: MRT_ColumnDef<Resource>,
  values: Resource,
  setValues: (values: Resource) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      label={column.header}
      name={column.accessorKey}
      disabled
      onChange={(e) =>
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        })
      }
      value={values[column.accessorKey] || ''}
      variant="standard"
    />
  );
};

const renderYesNoDropdownField = (
  column: MRT_ColumnDef<Resource>,
  values: Resource,
  setValues: (values: Resource) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      label={column.header}
      name={column.accessorKey}
      select
      onChange={(e) =>
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        })
      }
      value={values[column.accessorKey] || YesNoEnum.No}
      variant="standard"
      fullWidth={true}
    >
      {Object.values(YesNoEnum).map((item) => (
        <MenuItem key={item} value={item || ''}>
          {item === YesNoEnum.Yes ? 'Yes' : 'No'}
        </MenuItem>
      ))}
    </TextField>
  );
};

const renderRecordTypeDropdownField = (
  column: MRT_ColumnDef<Resource>,
  values: Resource,
  setValues: (values: Resource) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      select
      label={column.header}
      name={column.accessorKey}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        });
      }}
      value={values[column.accessorKey] || 'RAM'}
      variant="standard"
    >
      {['RAM', 'G2T', 'PSI', 'Internal'].map((item) => (
        <MenuItem key={item} value={item || ''}>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );
};

const renderProvinceDropdownField = (
  column: MRT_ColumnDef<Resource>,
  values: Resource,
  setValues: (values: Resource) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      select
      label={column.header}
      name={column.accessorKey}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        });
      }}
      value={values[column.accessorKey] || ''}
      variant="standard"
    >
      {provinces.map((item) => (
        <MenuItem key={item} value={item || ''}>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );
};

const renderCityDropdownField = (
  column: MRT_ColumnDef<Resource>,
  values: Resource,
  setValues: (values: Resource) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      select
      label={column.header}
      name={column.accessorKey}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        });
      }}
      value={values[column.accessorKey] || ''}
      variant="standard"
    >
      {values?.primaryLocationProvince ? (
        citiesByProvince
          .filter(
            (city) =>
              city.province === values?.primaryLocationProvince ||
              city.province ===
                AbbreviationsToProvinceMap[values?.primaryLocationProvince],
          )
          .map((city, index) => (
            <MenuItem key={index} value={city.city || ''}>
              {city.city}
            </MenuItem>
          ))
      ) : (
        <MenuItem value={''}>
          {intl.translate({
            id: 'Select a province first',
          })}
        </MenuItem>
      )}
    </TextField>
  );
};

export const ResourceForm: FC<ResourceFormProps> = ({
  columns,
  values,
  setValues,
  validationErrors,
  changedProperties,
  selectedSiteInfo,
}) => {
  // references
  const [accessibility, setAccessibilityId] = useState<string>(
    (isValidEnFr(values?.accessibility)
      ? values?.accessibility?._id ||
        values?.accessibility.objectId ||
        values?.accessibility
      : values?.accessibility) || '',
  );

  const [targetPopulations, setTargetPopulationsId] = useState<string[]>(
    values?.targetPopulations || [],
  );

  const [documentsRequired, setDocumentsRequiredId] = useState<string[]>(
    values?.documentsRequired || [],
  );
  const [feeStructureSource, setFeeStructureSourceId] = useState<string[]>(
    values?.feeStructureSource || [],
  );
  const [howIsServiceOffered, setHowIsServiceOfferedId] = useState<string[]>(
    values?.howIsServiceOffered || [],
  );
  const [howToAccessSupport, setHowToAccessSupportId] = useState<string[]>(
    values?.howToAccessSupport || [],
  );

  // TODO: in the future could be a reference
  const [languages, setLanguages] = useState<Language[]>(
    values?.languages !== null && typeof values?.languages !== 'string'
      ? values?.languages
      : [{ ...newLanguage }],
  );

  // arrays
  const [mailingAddresses, setMailingAddresses] = useState<MailingAddress[]>(
    values?.mailingAddresses !== null &&
      typeof values?.mailingAddresses !== 'string'
      ? values?.mailingAddresses
      : [{ ...newMailingAddress }],
  );
  const [physicalAddresses, setPhysicalAddresses] = useState<PhysicalAddress[]>(
    values?.physicalAddresses !== null &&
      typeof values?.physicalAddresses !== 'string'
      ? values?.physicalAddresses
      : [{ ...newPhysicalAddress }],
  );
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>(
    values?.phoneNumbers !== null && typeof values?.phoneNumbers !== 'string'
      ? values?.phoneNumbers
      : [{ ...newPhoneNumbers }],
  );
  const [operations, setOperations] = useState<Operations[]>(
    values?.operations && typeof values?.operations !== 'string'
      ? values?.operations
      : [{ ...newOperations }],
  );
  const [keywords, setKeywords] = useState<string[]>(
    values?.keywords !== null && typeof values?.keywords !== 'string'
      ? values?.keywords
      : [],
  );
  const [coverage, setCoverages] = useState<Coverage[]>(
    values?.coverage &&
      typeof values?.coverage !== 'string' &&
      values?.coverage.length > 0
      ? values?.coverage
      : [{ ...newCoverage }],
  );

  const getColumnByAccessorKey = useCallback(
    (accessorKey: string) => {
      return columns.find(
        (x) => x.accessorKey === accessorKey,
      ) as MRT_ColumnDef<Resource>;
    },
    [columns],
  );

  useEffect(() => {
    setValues({
      ...values,
      accessibility,
      targetPopulations,
      documentsRequired,
      feeStructureSource,
      howIsServiceOffered,
      howToAccessSupport,
      mailingAddresses,
      physicalAddresses,
      phoneNumbers,
      // volunteer,
      keywords,
      operations,
      languages,
      coverage,
    });
    // disable warning as we only want to trigger update for accessibilities
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessibility,
    targetPopulations,
    documentsRequired,
    feeStructureSource,
    howIsServiceOffered,
    howToAccessSupport,
    mailingAddresses,
    physicalAddresses,
    phoneNumbers,
    // volunteer,
    keywords,
    operations,
    languages,
    coverage,
  ]);

  const renderTextField = (
    column: MRT_ColumnDef<Resource>,
    values: Resource,
    setValues: (values: Resource) => void,
    isRequired?: boolean,
  ) => {
    if (!column.accessorKey) {
      return null;
    }

    if (changedProperties && changedProperties.includes(column.accessorKey)) {
      return (
        <TextField
          key={column.accessorKey}
          label={column.header}
          name={column.accessorKey}
          required={isRequired}
          error={!!validationErrors[column.accessorKey]}
          helperText={validationErrors[column.accessorKey]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
              ...values,
              [e.target.name]: e.target?.value,
            });
          }}
          value={values[column.accessorKey] || ''}
          variant="filled"
          color="success"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <UpdateIcon />
              </InputAdornment>
            ),
          }}
          focused
        />
      );
    }

    return (
      <TextField
        key={column.accessorKey}
        label={column.header}
        name={column.accessorKey}
        required={isRequired}
        error={!!validationErrors[column.accessorKey]}
        helperText={validationErrors[column.accessorKey]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValues({
            ...values,
            [e.target.name]: e.target?.value,
          });
        }}
        value={values[column.accessorKey] || ''}
        variant="standard"
      />
    );
  };

  const renderTextAreaField = (
    column: MRT_ColumnDef<Resource>,
    values: Resource,
    setValues: (values: Resource) => void,
    isRequired?: boolean,
  ) => {
    if (!column.accessorKey) {
      return null;
    }

    if (changedProperties && changedProperties.includes(column.accessorKey)) {
      return (
        <TextField
          key={column.accessorKey}
          label={column.header}
          name={column.accessorKey}
          required={isRequired}
          error={!!validationErrors[column.accessorKey]}
          helperText={validationErrors[column.accessorKey]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
              ...values,
              [e.target.name]: e.target?.value,
            });
          }}
          value={values[column.accessorKey]}
          multiline
          rows={4}
          variant="filled"
          color="success"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <UpdateIcon />
              </InputAdornment>
            ),
          }}
          focused
        />
      );
    }

    return (
      <TextField
        key={column.accessorKey}
        label={column.header}
        name={column.accessorKey}
        required={isRequired}
        error={!!validationErrors[column.accessorKey]}
        helperText={validationErrors[column.accessorKey]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValues({
            ...values,
            [e.target.name]: e.target?.value,
          });
        }}
        value={values[column.accessorKey] || ''}
        variant="standard"
        multiline
        rows={4}
      />
    );
  };

  const renderNumberTextField = (
    column: MRT_ColumnDef<Resource>,
    values: Resource,
    setValues: (values: Resource) => void,
    isRequired?: boolean,
  ) => {
    if (!column.accessorKey) {
      return null;
    }

    return (
      <TextField
        key={column.accessorKey}
        label={column.header}
        name={column.accessorKey}
        type="number"
        required={isRequired}
        error={!!validationErrors[column.accessorKey]}
        helperText={validationErrors[column.accessorKey]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValues({
            ...values,
            [e.target.name]: e.target?.value,
          });
        }}
        value={values[column.accessorKey] || ''}
        variant="standard"
      />
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLatitudeLongitudeLookup = async (values: any) => {
    try {
      const res = await getGeocodingData({
        address1: values['primaryLocationAddress1'] || '',
        address2: values['primaryLocationAddress2'] || '',
        country: values['locationCountry'] || 'Canada',
        province: values['primaryLocationProvince'] || '',
        county: values['primaryLocationCounty'] || '',
        city: values['primaryLocationCity'] || '',
        postalCode: values['primaryLocationPostalCode'] || '',
      });

      const { latitude, longitude } = res;

      setValues({
        ...values,
        ['locationLongitude']: longitude,
        ['locationLatitude']: latitude,
      });
    } catch (e) {
      toast.error('An error occured', {
        position: 'top-center',
      });
    }
  };

  return (
    <Stack
      sx={{
        width: '100%',
        minWidth: { xs: '300px', sm: '360px', md: '400px' },
        gap: '1.5rem',
        padding: 2,
      }}
    >
      {/* {getColumnByAccessorKey('objectId') && values['objectId'] && (
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {renderDisabledTextField(
            getColumnByAccessorKey('objectId'),
            values,
            setValues,
          )}
        </Box>
      )} */}

      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '102ch' },
        }}
      >
        {!selectedSiteInfo &&
          renderDisabledTextField(
            getColumnByAccessorKey('khpReferenceNumber'),
            values,
            setValues,
          )}
        {selectedSiteInfo &&
          renderTextField(
            getColumnByAccessorKey('khpReferenceNumber'),
            values,
            setValues,
          )}
        {renderRecordTypeDropdownField(
          getColumnByAccessorKey('recordType'),
          values,
          setValues,
        )}
        {renderDisabledTextField(
          getColumnByAccessorKey('lastVerifiedOn'),
          values,
          setValues,
        )}
        {getColumnByAccessorKey('updatedAt') &&
          values['updatedAt'] &&
          renderDisabledTextField(
            getColumnByAccessorKey('updatedAt'),
            values,
            setValues,
          )}
        {getColumnByAccessorKey('lastUpdatedBy') &&
          values['lastUpdatedBy'] &&
          renderDisabledTextField(
            getColumnByAccessorKey('lastUpdatedBy'),
            values,
            setValues,
          )}
        {renderYesNoDropdownField(
          getColumnByAccessorKey('isHighlighted'),
          values,
          setValues,
        )}
      </Box>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '50ch' },
        }}
      >
        {renderTextField(
          getColumnByAccessorKey('metadataEn'),
          values,
          setValues,
        )}
        {renderTextField(
          getColumnByAccessorKey('metadataFr'),
          values,
          setValues,
        )}
      </Box>

      <BoxContent
        title={intl.translate({
          id: 'Name Details',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '50ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('nameEn'),
            values,
            setValues,
            true,
          )}
          {renderTextField(
            getColumnByAccessorKey('nameFr'),
            values,
            setValues,
            true,
          )}
          {renderTextField(
            getColumnByAccessorKey('nameDetailsEnOfficial'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('nameDetailsFrOfficial'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('nameDetailsEnAlternate'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('nameDetailsFrAlternate'),
            values,
            setValues,
          )}
        </Box>
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Description',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {renderTextAreaField(
            getColumnByAccessorKey('descriptionEn'),
            values,
            setValues,
            true,
          )}
          {renderTextAreaField(
            getColumnByAccessorKey('descriptionFr'),
            values,
            setValues,
            true,
          )}
        </Box>

        <HowIsServiceOfferedForm
          value={howIsServiceOffered || ''}
          setValue={setHowIsServiceOfferedId}
          currentValues={values?.feeStructureSource}
        />
        <HowToAccessSupportForm
          value={howToAccessSupport || ''}
          setValue={setHowToAccessSupportId}
          currentValues={values?.howToAccessSupport}
        />
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Eligibility',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '50ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('eligibilityDetailsPhraseEn'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('eligibilityDetailsPhraseFr'),
            values,
            setValues,
          )}
        </Box>

        <TargetPopulationForm
          value={targetPopulations || ''}
          setValue={setTargetPopulationsId}
          currentValues={values?.targetPopulations}
        />
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {renderNumberTextField(
            getColumnByAccessorKey('eligibilityMinAge'),
            values,
            setValues,
          )}
          {renderNumberTextField(
            getColumnByAccessorKey('eligibilityMaxAge'),
            values,
            setValues,
          )}
        </Box>

        <LanguagesForm value={languages || ''} setValue={setLanguages} />
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {renderYesNoDropdownField(
            getColumnByAccessorKey(
              'interpretationTranslationServicesAvailable',
            ),
            values,
            setValues,
          )}
        </Box>
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Application Process',
        })}
      >
        <ApplicationProcessForm values={values} setValues={setValues} />
        <DocumentsRequiredForm
          value={documentsRequired || ''}
          setValue={setDocumentsRequiredId}
          currentValues={values?.documentsRequired}
        />
        <FeeStructureSourceForm
          value={feeStructureSource || ''}
          setValue={setFeeStructureSourceId}
          currentValues={values?.feeStructureSource}
        />
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('feeStructureSourceFreeTextEn'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('feeStructureSourceFreeTextFr'),
            values,
            setValues,
          )}
        </Box>
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Coverage',
        })}
      >
        <CoverageForm
          value={coverage}
          setValue={setCoverages}
          currentSites={values?.sites || selectedSiteInfo}
        />
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Capacity',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '50ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('capacityEnType'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('capacityFrType'),
            values,
            setValues,
          )}
        </Box>
        {/* {renderTextField(
          getColumnByAccessorKey('capacityEnValue'),
          values,
          setValues,
        )}
        {renderTextField(
          getColumnByAccessorKey('capacityFrValue'),
          values,
          setValues,
        )} */}
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Main Contact Information',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('mainContactName'),
            values,
            setValues,
          )}
        </Box>
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '50ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('mainContactTitleEn'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('mainContactTitleFr'),
            values,
            setValues,
          )}
        </Box>
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('mainContactPhone'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('mainContactEmail'),
            values,
            setValues,
          )}
        </Box>
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Location',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('primaryLocationAddress1'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('primaryLocationAddress2'),
            values,
            setValues,
          )}
          {renderProvinceDropdownField(
            getColumnByAccessorKey('primaryLocationProvince'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('primaryLocationCounty'),
            values,
            setValues,
          )}
          {renderCityDropdownField(
            getColumnByAccessorKey('primaryLocationCity'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('primaryLocationPostalCode'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('primaryLocationPhone'),
            values,
            setValues,
          )}
          {renderYesNoDropdownField(
            getColumnByAccessorKey('primaryLocationIsPrivate'),
            values,
            setValues,
          )}
          <Box>
            <Tooltip title="Lookup location data">
              <IconButton
                color="primary"
                aria-label="Lookup Latitude and Longitude"
                component="label"
                onClick={() => handleLatitudeLongitudeLookup(values)}
              >
                <Search />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Accessibility',
        })}
      >
        <AccessibilityForm
          value={accessibility || ''}
          setValue={setAccessibilityId}
          currentValue={values?.accessibility}
        />
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Social Media',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '50ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('websiteEn'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('websiteFr'),
            values,
            setValues,
          )}
        </Box>
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('socialFacebook'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('socialInstagram'),
            values,
            setValues,
          )}
        </Box>
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Phone Numbers',
        })}
      >
        {changedProperties?.includes('phoneNumbers') && (
          <div style={{ color: 'green' }}>
            <UpdateIcon />
          </div>
        )}
        <PhoneNumbersForm
          value={phoneNumbers || ''}
          setValue={setPhoneNumbers}
        />
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Hours of Operation',
        })}
      >
        <OperationsForm
          value={operations || ''}
          setValue={setOperations}
          currentSites={values?.sites}
        />
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Keywords',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          <KeywordsForm value={keywords || ''} setValue={setKeywords} />
        </Box>
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Miscellaneous',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '50ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('transportationEn'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('transportationFr'),
            values,
            setValues,
          )}
        </Box>
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {renderYesNoDropdownField(
            getColumnByAccessorKey('available247'),
            values,
            setValues,
          )}
          {getColumnByAccessorKey('createdAt') &&
            values['createdAt'] &&
            renderDisabledTextField(
              getColumnByAccessorKey('createdAt'),
              values,
              setValues,
            )}
        </Box>
      </BoxContent>
    </Stack>
  );
};
