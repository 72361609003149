import { STATUS_ACTIVE, STATUS_DEACTIVATED } from 'global-constants/resources';
import { MRT_Cell, MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Resource } from 'types/entities/resources';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

export const useResources = () => {
  const getCommonDateCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    const date = cell.getValue<Date>();

    return date ? new Date(date).toLocaleDateString() : '';
  };

  const getStatusCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    const status = cell.getValue<boolean>() || false;

    return status ? STATUS_ACTIVE : STATUS_DEACTIVATED;
  };

  const getBooleanCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    const value = cell.getValue<boolean>() || false;
    return value ? 'Yes' : 'No';
  };

  const getJsonCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    try {
      // const json = JSON.parse(cell.getValue<string>() || '{}');
      const jsonAsString = JSON.stringify(cell.getValue<string>(), null, 4);
      return (
        <textarea
          disabled
          style={{
            background: 'inherit',
            height: '100px',
            border: 'none',
          }}
          value={jsonAsString || ''}
        ></textarea>
      );
    } catch (e: unknown) {
      if (e instanceof Error) {
        logger.error(e);
      }
      return null;
    }
  };

  const getListCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    try {
      const value = cell.getValue<string>();
      return (
        <textarea
          disabled
          style={{
            background: 'inherit',
            height: '50px',
            border: 'none',
          }}
          value={value || ''}
        ></textarea>
      );
    } catch (e: unknown) {
      if (e instanceof Error) {
        logger.error(e);
      }
      return null;
    }
  };

  const columns = useMemo<MRT_ColumnDef<Resource>[]>(
    () => [
      {
        accessorFn: (row) => row._id || row.id,
        accessorKey: '_id',
        header: intl.translate({
          id: 'Object ID',
        }),
        enableEditing: false,
        enableHiding: false,
      },
      {
        accessorKey: 'khpReferenceNumber',
        header: intl.translate({
          id: 'Khp Reference Number',
        }),
      },
      {
        accessorKey: 'nameEn',
        header: intl.translate({
          id: 'Resource Name En',
        }),
      },
      {
        accessorKey: 'nameFr',
        header: intl.translate({
          id: 'Resource Name Fr',
        }),
      },
      {
        accessorKey: 'siteNamesEn',
        header: intl.translate({
          id: 'Site(s) Name En',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },
      {
        accessorKey: 'siteNamesFr',
        header: intl.translate({
          id: 'Site(s) Name Fr',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },
      {
        accessorKey: 'agencyNameEn',
        header: intl.translate({
          id: 'Agency Name En',
        }),
      },

      {
        accessorKey: 'agencyNameFr',
        header: intl.translate({
          id: 'Agency Name Fr',
        }),
      },
      {
        accessorKey: 'taxonomyNamesEn',
        header: intl.translate({
          id: 'Taxonomy(s) Name En',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },

      {
        accessorKey: 'taxonomyNamesFr',
        header: intl.translate({
          id: 'Taxonomy(s) Name Fr',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },
      {
        accessorKey: 'descriptionEn',
        header: intl.translate({
          id: 'Resource Description En',
        }),
      },
      {
        accessorKey: 'descriptionFr',
        header: intl.translate({
          id: 'Resource Description Fr',
        }),
      },

      {
        accessorKey: 'nameDetailsEnOfficial',
        header: intl.translate({
          id: 'Resource Name Details En Official',
        }),
      },
      {
        accessorKey: 'nameDetailsEnAlternate',
        header: intl.translate({
          id: 'Resource Name Details En Alternate',
        }),
      },
      {
        accessorKey: 'nameDetailsFrOfficial',
        header: intl.translate({
          id: 'Resource Name Details Fr Official',
        }),
      },
      {
        accessorKey: 'nameDetailsFrAlternate',
        header: intl.translate({
          id: 'Resource Name Details Fr Alternate',
        }),
      },
      {
        accessorKey: 'recordType',
        header: intl.translate({
          id: 'Record Type',
        }),
      },
      {
        accessorKey: 'applicationProcessEn',
        header: intl.translate({
          id: 'Application Process En',
        }),
      },
      {
        accessorKey: 'applicationProcessFr',
        header: intl.translate({
          id: 'Application Process Fr',
        }),
      },
      {
        accessorKey: 'capacityEnType',
        header: intl.translate({
          id: 'Capacity En Type',
        }),
      },
      {
        accessorKey: 'capacityEnValue',
        header: intl.translate({
          id: 'Capacity En Value',
        }),
      },
      {
        accessorKey: 'capacityFrType',
        header: intl.translate({
          id: 'Capacity Fr Type',
        }),
      },
      {
        accessorKey: 'capacityFrValue',
        header: intl.translate({
          id: 'Capacity Fr Value',
        }),
      },
      {
        accessorKey: 'socialFacebook',
        header: intl.translate({
          id: 'Resource Facebook',
        }),
      },
      {
        accessorKey: 'socialInstagram',
        header: intl.translate({
          id: 'Resource Instagram',
        }),
      },
      {
        accessorKey: 'socialTwitter',
        header: intl.translate({
          id: 'Resource Twitter',
        }),
      },
      {
        accessorKey: 'eligibilityDetailsPhraseEn',
        header: intl.translate({
          id: 'Eligibility Details En',
        }),
      },
      {
        accessorKey: 'eligibilityDetailsPhraseFr',
        header: intl.translate({
          id: 'Eligibility Details Fr',
        }),
      },
      {
        accessorKey: 'eligibilityDetailsAdult',
        header: intl.translate({
          id: 'Eligibility Details Adult',
        }),
        Cell: ({ cell }) => {
          return getBooleanCellProps(cell);
        },
      },
      {
        accessorKey: 'eligibilityDetailsChild',
        header: intl.translate({
          id: 'Eligibility Details Child',
        }),
        Cell: ({ cell }) => {
          return getBooleanCellProps(cell);
        },
      },
      {
        accessorKey: 'eligibilityDetailsFamily',
        header: intl.translate({
          id: 'Eligibility Details Family',
        }),
        Cell: ({ cell }) => {
          return getBooleanCellProps(cell);
        },
      },
      {
        accessorKey: 'eligibilityDetailsGender',
        header: intl.translate({
          id: 'Eligibility Details Gender',
        }),
      },
      {
        accessorKey: 'eligibilityDetailsTeen',
        header: intl.translate({
          id: 'Eligibility Details Teen',
        }),
        Cell: ({ cell }) => {
          return getBooleanCellProps(cell);
        },
      },
      {
        accessorKey: 'metadataEn',
        header: intl.translate({
          id: 'Metadata En',
        }),
      },
      {
        accessorKey: 'metadataFr',
        header: intl.translate({
          id: 'Metadata Fr',
        }),
      },
      {
        accessorKey: 'transportationEn',
        header: intl.translate({
          id: 'Transportation En',
        }),
      },
      {
        accessorKey: 'transportationFr',
        header: intl.translate({
          id: 'Transportation Fr',
        }),
      },
      {
        accessorKey: 'seniorOrgContactTitleEn',
        header: intl.translate({
          id: 'Resource Senior Org Contact Title En',
        }),
      },
      {
        accessorKey: 'seniorOrgContactTitleFr',
        header: intl.translate({
          id: 'Resource Senior Org Contact Title Fr',
        }),
      },
      {
        accessorKey: 'seniorOrgContactEmail',
        header: intl.translate({
          id: 'Resource Senior Org Contact Email',
        }),
      },
      {
        accessorKey: 'seniorOrgContactIsPrivate',
        header: intl.translate({
          id: 'Senior Org Contact Is Private',
        }),
        Cell: ({ cell }) => {
          return getBooleanCellProps(cell);
        },
      },
      {
        accessorKey: 'seniorOrgContactName',
        header: intl.translate({
          id: 'Senior Org Contact Name',
        }),
      },
      {
        accessorKey: 'seniorOrgContactPhone',
        header: intl.translate({
          id: 'Senior Org Contact Phone',
        }),
      },

      {
        accessorKey: 'lastVerifiedOn',
        header: intl.translate({
          id: 'Last Verified',
        }),
        Cell: ({ cell }) => {
          return getCommonDateCellProps(cell);
        },
      },
      {
        accessorKey: 'isActive',
        header: intl.translate({
          id: 'Status',
        }),
        Cell: ({ cell }) => {
          return getStatusCellProps(cell);
        },
      },
      {
        accessorKey: 'mailingAddressesString',
        header: intl.translate({
          id: 'Mailing Addresses',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },
      {
        accessorKey: 'mailingAddresses',
        header: intl.translate({
          id: 'Mailing Addresses (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'physicalAddressesString',
        header: intl.translate({
          id: 'Physical Addresses',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },
      {
        accessorKey: 'physicalAddresses',
        header: intl.translate({
          id: 'Physical Addresses (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'primaryLocationCity',
        header: intl.translate({
          id: 'Primary Location City',
        }),
      },
      {
        accessorKey: 'primaryLocationCounty',
        header: intl.translate({
          id: 'Primary Location County',
        }),
      },
      {
        accessorKey: 'primaryLocationPostalCode',
        header: intl.translate({
          id: 'Primary Location Postal Code',
        }),
      },
      {
        accessorKey: 'primaryLocationPhone',
        header: intl.translate({
          id: 'Primary Location Phone',
        }),
      },
      {
        accessorKey: 'primaryLocationAddress1',
        header: intl.translate({
          id: 'Primary Location Address 1',
        }),
      },
      {
        accessorKey: 'primaryLocationAddress2',
        header: intl.translate({
          id: 'Primary Location Address 2',
        }),
      },
      {
        accessorKey: 'primaryLocationProvince',
        header: intl.translate({
          id: 'Primary Location Province',
        }),
      },
      {
        accessorKey: 'primaryLocationIsPrivate',
        header: intl.translate({
          id: 'Primary Location Is Private',
        }),
        Cell: ({ cell }) => {
          return getBooleanCellProps(cell);
        },
      },
      // {
      //   accessorKey: 'coverageEn',
      //   header: intl.translate({
      //     id: 'Coverage En',
      //   }),
      // },
      // {
      //   accessorKey: 'coverageFr',
      //   header: intl.translate({
      //     id: 'Coverage Fr',
      //   }),
      // },
      {
        accessorKey: 'targetPopulationsEn',
        header: intl.translate({
          id: 'Target Populations En',
        }),
      },
      {
        accessorKey: 'targetPopulationsFr',
        header: intl.translate({
          id: 'Target Populations Fr',
        }),
      },
      {
        accessorKey: 'targetPopulations',
        header: intl.translate({
          id: 'Target Populations (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'eligibilityMinAge',
        header: intl.translate({
          id: 'Eligibility Min Age',
        }),
      },
      {
        accessorKey: 'eligibilityMaxAge',
        header: intl.translate({
          id: 'Eligibility Max Age',
        }),
      },
      {
        accessorKey: 'phoneNumbersString',
        header: intl.translate({
          id: 'Phone Numbers',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },
      {
        accessorKey: 'phoneNumbers',
        header: intl.translate({
          id: 'Phone Numbers (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'mainContactTitleEn',
        header: intl.translate({
          id: 'Main Contact Title En',
        }),
      },
      {
        accessorKey: 'mainContactTitleFr',
        header: intl.translate({
          id: 'Main Contact Title Fr',
        }),
      },
      {
        accessorKey: 'mainContactEmail',
        header: intl.translate({
          id: 'Main Contact Email',
        }),
      },
      {
        accessorKey: 'mainContactIsPrivate',
        header: intl.translate({
          id: 'Main Contact Is Private',
        }),
        Cell: ({ cell }) => {
          return getBooleanCellProps(cell);
        },
      },
      {
        accessorKey: 'mainContactName',
        header: intl.translate({
          id: 'Main Contact Name',
        }),
      },
      {
        accessorKey: 'mainContactPhone',
        header: intl.translate({
          id: 'Main Contact Phone',
        }),
      },
      {
        accessorKey: 'documentsRequiredEn',
        header: intl.translate({
          id: 'Documents Required En',
        }),
      },
      {
        accessorKey: 'documentsRequiredFr',
        header: intl.translate({
          id: 'Documents Required Fr',
        }),
      },
      {
        accessorKey: 'documentsRequired',
        header: intl.translate({
          id: 'Documents Required (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'operationsEn',
        header: intl.translate({
          id: 'Operations En',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },
      {
        accessorKey: 'operationsFr',
        header: intl.translate({
          id: 'Operations Fr',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },
      {
        accessorKey: 'operations',
        header: intl.translate({
          id: 'Operations (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'languagesString',
        header: intl.translate({
          id: 'Languages',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },
      {
        accessorKey: 'languages',
        header: intl.translate({
          id: 'Languages (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'websiteEn',
        header: intl.translate({
          id: 'WebsiteEn',
        }),
      },
      {
        accessorKey: 'websiteFr',
        header: intl.translate({
          id: 'WebsiteFr',
        }),
      },
      {
        accessorKey: 'interpretationTranslationServicesAvailable',
        header: intl.translate({
          id: 'Interpretation Translation Services Available',
        }),
        Cell: ({ cell }) => {
          return getBooleanCellProps(cell);
        },
      },
      {
        accessorKey: 'available247',
        header: intl.translate({
          id: 'Available 247',
        }),
      },
      {
        accessorKey: 'feeStructureSourceEn',
        header: intl.translate({
          id: 'Cost For Service En',
        }),
      },
      {
        accessorKey: 'feeStructureSourceFr',
        header: intl.translate({
          id: 'Cost For Service Fr',
        }),
      },
      {
        accessorKey: 'feeStructureSourceFreeTextEn',
        header: intl.translate({
          id: 'Cost For Service Free Text En',
        }),
      },
      {
        accessorKey: 'feeStructureSourceFreeTextFr',
        header: intl.translate({
          id: 'Cost For Service Free Text Fr',
        }),
      },
      {
        accessorKey: 'feeStructureSource',
        header: intl.translate({
          id: 'Fee Structure Source (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'howIsServiceOfferedEn',
        header: intl.translate({
          id: 'How Is Service Offered En',
        }),
      },
      {
        accessorKey: 'howIsServiceOfferedFr',
        header: intl.translate({
          id: 'How Is Service Offered Fr',
        }),
      },
      {
        accessorKey: 'howIsServiceOffered',
        header: intl.translate({
          id: 'How Is Service Offered (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'accessibilityEn',
        header: intl.translate({
          id: 'Accessibility En',
        }),
      },
      {
        accessorKey: 'accessibilityFr',
        header: intl.translate({
          id: 'Accessibility Fr',
        }),
      },
      {
        accessorKey: 'accessibility',
        header: intl.translate({
          id: 'Accessibility (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      // {
      //   accessorKey: 'volunteerEn',
      //   header: intl.translate({
      //     id: 'Volunteer En',
      //   }),
      // },
      // {
      //   accessorKey: 'volunteerFr',
      //   header: intl.translate({
      //     id: 'Volunteer Fr',
      //   }),
      // },
      // {
      //   accessorKey: 'volunteer',
      //   header: intl.translate({
      //     id: 'Volunteer (all data)',
      //   }),
      //   Cell: ({ cell }) => {
      //     return getJsonCellProps(cell);
      //   },
      // },
      {
        accessorKey: 'howToAccessSupportEn',
        header: intl.translate({
          id: 'How To Access Support En',
        }),
      },
      {
        accessorKey: 'howToAccessSupportFr',
        header: intl.translate({
          id: 'How To Access Support Fr',
        }),
      },
      {
        accessorKey: 'howToAccessSupport',
        header: intl.translate({
          id: 'How To Access Support (all data)',
        }),
        Cell: ({ cell }) => {
          return getJsonCellProps(cell);
        },
      },
      {
        accessorKey: 'isHighlighted',
        header: intl.translate({
          id: 'Is Highlighted',
        }),
        Cell: ({ cell }) => {
          return getBooleanCellProps(cell);
        },
      },
      {
        accessorKey: 'keywords',
        header: intl.translate({
          id: 'Keywords',
        }),
      },
      {
        accessorKey: 'lastUpdatedBy',
        header: intl.translate({
          id: 'Last Updated By',
        }),
      },
      {
        accessorKey: 'updatedAt',
        header: intl.translate({
          id: 'Updated At',
        }),
        Cell: ({ cell }) => {
          return getCommonDateCellProps(cell);
        },
      },
      {
        accessorKey: 'createdAt',
        header: intl.translate({
          id: 'Created At',
        }),
        Cell: ({ cell }) => {
          return getCommonDateCellProps(cell);
        },
      },
    ],
    [],
  );

  return {
    columns,
  };
};
