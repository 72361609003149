/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Grid, MenuItem, TextField } from '@mui/material';
import applicationProcess from 'assets/constants/applicationProcess';
import React, { FC, useEffect } from 'react';
import { Resource } from 'types/entities/resources';

const ApplicationProcessForm: FC<{
  values: Resource;
  setValues: (values: Resource) => void;
}> = ({ values, setValues }) => {
  const [selectedProcessIndex, setSelectedProcessIndex] =
    React.useState<number>(0);

  useEffect(() => {
    const applicationProcessArray = applicationProcess.map(
      (process) => process.en,
    );
    if (
      values.applicationProcessEn &&
      applicationProcessArray.includes(values.applicationProcessEn)
    ) {
      const index = applicationProcessArray.findIndex(
        (process) => process === values.applicationProcessEn,
      );
      setSelectedProcessIndex(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '102ch' },
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              select
              label={'Application Process En'}
              name={'Application Process En'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const selectedProcess =
                  applicationProcess[parseInt(e.target?.value)];
                setSelectedProcessIndex(parseInt(e.target?.value));
                setValues({
                  ...values,
                  applicationProcessEn: selectedProcess.en,
                  applicationProcessFr: selectedProcess.fr,
                });
              }}
              value={selectedProcessIndex}
              variant="standard"
              fullWidth={true}
            >
              {applicationProcess.map((item, index) => (
                <MenuItem key={index} value={index}>
                  {item.en}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              label={'Application Process Fr'}
              name={'Application Process Fr'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const selectedProcess =
                  applicationProcess[parseInt(e.target?.value)];
                setSelectedProcessIndex(parseInt(e.target?.value));
                setValues({
                  ...values,
                  applicationProcessEn: selectedProcess.en,
                  applicationProcessFr: selectedProcess.fr,
                });
              }}
              value={selectedProcessIndex}
              variant="standard"
              fullWidth={true}
            >
              {applicationProcess.map((item, index) => (
                <MenuItem key={index} value={index}>
                  {item.fr}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              disabled
              label={'Application Process En Value'}
              onChange={(e) => {
                setValues({
                  ...values,
                  applicationProcessEn: e.target?.value,
                });
              }}
              value={values['applicationProcessEn'] || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              label={'Application Process Fr Value'}
              onChange={(e) => {
                setValues({
                  ...values,
                  applicationProcessFr: e.target?.value,
                });
              }}
              value={values['applicationProcessFr'] || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ApplicationProcessForm;
